<template>
  <v-container grid-list-xl fluid>
    <header-title title="Estadística por ESFM/UA"></header-title>
    <v-row class="text-center">
      <v-col cols="12" sm="3">
        <v-select
          label="Periodo y Gestión"
          :items="periodYears"
          v-model="period_years"
          item-text="periodo_gestion"
          item-value="periodo_gestion"
          @change="getEstadisticas"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="period_years != ''">
      <v-row class="mb-1a text-center bg-row-grey">
        <v-col cols="12" sm="6" md="4">
          <CardQuantity
            bgcolor="green accent-1"
            txtcolor="green darken-2"
            icon="mdi-account-supervisor"
            title="Personal"
            :quantity="quantity.staff"
            description="Cantidad de personal administrativo"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <CardQuantity
            link="/teacher/report"
            bgcolor="light-blue lighten-4"
            txtcolor="blue darken-2"
            icon="mdi-account-group"
            title="Docentes"
            :quantity="quantity.teacher"
            description="Cantidad docentes de ESFM/UA"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <CardQuantity
            link="/student/report"
            bgcolor="cyan lighten-4"
            txtcolor="cyan darken-2"
            icon="mdi-card-account-details"
            title="Estudiantes"
            :quantity="quantity.student"
            description="Cantidad estudiantes de ESFM/UA"
          />
        </v-col>
      </v-row>
      <v-row class="bg-row-grey">
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" elevation="3">
            <highcharts
              class="chart"
              :options="chartExecutiveGender"
            ></highcharts>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" elevation="3">
            <highcharts
              class="chart"
              :options="chartTeacherGender"
            ></highcharts>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" elevation="3">
            <highcharts
              class="chart"
              :options="chartStudentGender"
            ></highcharts>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" elevation="3">
            <highcharts
              class="chart"
              :options="chartStudentSpeciality"
            ></highcharts>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <span class="red--text"
        >No hay registros para visualizar o no selecciono el Periodo y
        Gestión.</span
      >
    </div>
  </v-container>
</template>

<script>
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";

import HeaderTitle from "@/components/HeaderTitle";
import CardQuantity from "@/components/CardQuantity";
import Storage from "../../utils/storage";
import Service from "../../services/request";
exportingInit(Highcharts);
highchartsMore(Highcharts);
export default {
  name: "admision-dashboard",
  components: {
    HeaderTitle,
    CardQuantity,
  },
  data: () => ({
    message: "",
    loading: false,
    periodYears: [],
    period_years: "",
    institution_id: "",
    quantity: {
      staff: 0,
      teacher: 0,
      student: 0,
    },
    chartExecutiveGender: {},
    chartTeacherGender: {},
    chartStudentGender: {},
    chartStudentSpeciality: {},
    user: null,
  }),
  created() {
    this.user = Storage.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [8, 11];
    if (this.user && roles.includes(this.user.rid)) {
      this.institution_id = this.user.ies;
      this.getPeriodYearParameter();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    getPeriodYearParameter() {
      Service.svcPeriodYears("get")
        .then((response) => {
          this.periodYears = response.data[0];
          const year = this.periodYears.find((item) => item.vigente == 1);
          this.period_years = year ? year.periodo_gestion : "";
          this.getEstadisticas();
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    getEstadisticas() {
      this.quantity = {
        staff: 0,
        teacher: 0,
        student: 0,
      };

      Service.svcStatisticInstitution("post", {
        period_years: this.period_years,
        institution_id: this.institution_id,
        request_type: "a",
      })
        .then((response) => {
          const colors = [
            "#9CCC65",
            "#FB8C00",
            "#4DD0E1",
            /* "#BA68C8",
            "#00B0FF", */
          ];
          this.quantity.staff = response.data[0].total;
          this.quantity.teacher = response.data[1].total;
          this.quantity.student = response.data[2].total;

          let student_staff = {
            speciality: [],
            quantity: [],
          };
          const specialityStaff = response.data[3];
          for (const key in specialityStaff) {
            student_staff.speciality.push(specialityStaff[key].especialidad);
            student_staff.quantity.push(specialityStaff[key].total);
          }

          this.chartExecutiveGender = {
            chart: {
              type: "column",
            },
            title: {
              text: "PERSONAL POR GENERO",
            },
            subtitle: {
              text: "Cantidad de personal directivo, administrativo y de servicio",
            },
            xAxis: {
              categories: ["Directivo", "Administrativo", "Servicio"],
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              headerFormat: "{series.name}",
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
            },
            plotOptions: {
              column: {
                colorByPoint: false,
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              enabled: true,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Masculino",
                color: colors[0],
                data: [
                  response.data[0].dmasculino,
                  response.data[0].amasculino,
                  response.data[0].smasculino,
                ],
              },
              {
                name: "Femenino",
                data: [
                  response.data[0].dfemenino,
                  response.data[0].afemenino,
                  response.data[0].sfemenino,
                ],
                color: colors[1],
              },
              {
                name: "Otro",
                data: [
                  response.data[0].dother,
                  response.data[0].aother,
                  response.data[0].sother,
                ],
                color: colors[2],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartTeacherGender = {
            chart: {
              type: "pie",
            },
            title: {
              text: "DOCENTES POR GENERO",
            },
            subtitle: {
              text: "cantidad de docentes de las Escuelas Superiores de Formación de Maestros y Unidades Académicas",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                colors: colors,
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b><br>Total: {point.y}<br>{point.percentage:.1f} %",
                  distance: -50,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
                showInLegend: true,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Cantidad",
                colorByPoint: true,
                data: [
                  {
                    name: "Masculino",
                    y: response.data[1].masculino * 1,
                    sliced: true,
                    selected: true,
                  },
                  {
                    name: "Femenino",
                    y: response.data[1].femenino * 1,
                  },
                  {
                    name: "Otro",
                    y: response.data[1].otro * 1,
                  },
                ],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartStudentGender = {
            chart: {
              type: "pie",
            },
            title: {
              text: "ESTUDIANTES POR GENERO",
            },
            subtitle: {
              text: "cantidad de estudiantes de las Escuelas Superiores de Formación de Maestros y Unidades Académicas",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                colors: colors,
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b><br>Total: {point.y}<br>{point.percentage:.1f} %",
                  distance: -50,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
                showInLegend: true,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Cantidad",
                colorByPoint: true,
                data: [
                  {
                    name: "Masculino",
                    y: response.data[2].masculino * 1,
                    sliced: true,
                    selected: true,
                  },
                  {
                    name: "Femenino",
                    y: response.data[2].femenino * 1,
                  },
                  {
                    name: "Otro",
                    y: response.data[2].otro * 1,
                  },
                ],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartStudentSpeciality = {
            chart: {
              type: "bar",
            },
            title: {
              text: "ESTUDIANTES POR ESPECIALIDAD",
            },
            subtitle: {
              text: "Cantidad de estudiantes por especialidad segun ESFM/UA",
            },
            xAxis: {
              categories: student_staff.speciality,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              headerFormat: "{series.name}",
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
            },
            plotOptions: {
              bar: {
                colorByPoint: true,
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: {
              name: "Estudiantes",
              data: student_staff.quantity,
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
        })
        .catch(() => {
          console.error("datos no encontrado");
        });
    },
  },
};
</script>
<style scoped>
.head-color-1 {
  background-color: #616161;
}
.head-color-1 th {
  color: white !important;
}
.head-color-2 {
  background-color: #e0e0e0;
}
</style>
